<template>
<Impressum></Impressum>
</template>

<script>
import Impressum from "../../components/Startseite/Impressum";

export default {
components: {Impressum}
}
</script>

<style scoped>

</style>