<template>
  <div>
    <div class="generic__site">
      <Gallery></Gallery>
      <h2>
        Unternehmensgründerinnen und -gründer sind motiviert, ihre Pläne wirksam zu
        realisieren
      </h2>
      <p>
        Ambitionierte Start-ups arbeiten von Anfang an mit einem versierten
        Partner zusammen, der bodenständig, strategisch klug und wirtschaftlich
        tragfähig denkt und agiert. Und dafür haben Sie uns.
      </p>
      <p>Wir beraten und begleiten Sie, wenn es darum geht,</p>
      <ul>
        <li><span>Ihr Vorhaben wirtschaftlich tragfähig aufzusetzen,</span></li>
        <li><span>die adäquate Rechtsform zu wählen,</span></li>
        <li><span>Rentabilitätsberechnungen zu erstellen,</span></li>
        <li><span>wichtige Dokumente mit Notaren abzustimmen sowie</span></li>
        <li>
          <span>
            unumgängliche Formalitäten zu erledigen – wie gegenüber den
            Finanzbehörden und den Sozialversicherungsträgern.
          </span>
        </li>
      </ul>
      <p>
        Wir stellen die steuerlichen und betriebswirtschaftlichen
        Weichen für Ihren Geschäftserfolg.
      </p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery";
import Footer from "@/components/Footer";

export default {
  name: "StartUps",
  metaInfo: {
    title: 'Start-ups',
    titleTemplate: '%s | Kammers & Partner'
  },
  components: { Footer, Gallery }
};
</script>

<style scoped></style>
