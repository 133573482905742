<template>
  <div>
    <header id="head__id">
      <div style="    display: flex;justify-content: center;">
        <router-link
          id="headr"
          style=" font-size: 1.3rem; font-weight: 200;  letter-spacing: 0.1rem!important"
          to="/"
          class="headline"
          @click.native="closeSubNav"
          >KAMMERS & PARTNER
        </router-link>
      </div>

      <nav id="top_nav">
        <label
          id="kanzlei"
          :class="{
            active__router: subIsActive([
              '/kompetenz',
              '/portraet',
              '/haltung',
              '/'
            ])
          }"
          @click="openSubNav('kanzlei')"
          >Kanzlei</label
        >
        <label
          id="leistungen"
          :class="{
            active__router: subIsActive([
              '/unternehmen',
              '/stiftungen',
              '/start-ups',
              '/privatpersonen',
              '/'
            ])
          }"
          @click="openSubNav('leistungen')"
          >Leistungen</label
        >
        <label
          id="menschen"
          :class="{
            active__router: subIsActive([
              '/partner',
              '/team',
              '/gesundheit',
              '/'
            ])
          }"
          @click="openSubNav('menschen')"
          >Menschen</label
        >
        <label
          id="karriere"
          :class="{
            active__router: subIsActive([
              '/partnerschaft',
              '/stellenangebote',
              '/weiterbildung',
              '/ihr-arbeitgeber',
              '/'
            ])
          }"
          @click="openSubNav('karriere')"
          >Karriere</label
        >

        <label
          id="aktuelles"
          :class="{
            active__router: subIsActive([
              '/veroeffentlichungen',
              '/personelles',
              '/'
            ])
          }"
          @click="openSubNav('aktuelles')"
          >Aktuelles</label
        >
      </nav>
      <nav id="sub__nav">
        <div v-if="this.topRoute === 'kanzlei'">
          <router-link
            :class="{
              active__router: subIsActive(['/'])
            }"
            to="/portraet"
            @click.native="closeSubNav"
            >Porträt
          </router-link>
          <router-link
            :class="{
              active__router: subIsActive(['/'])
            }"
            to="/haltung"
            @click.native="closeSubNav"
            >Haltung
          </router-link>
          <router-link
            :class="{
              active__router: subIsActive(['/'])
            }"
            to="/kompetenz"
            @click.native="closeSubNav"
            >Kompetenz
          </router-link>
        </div>
        <div v-if="this.topRoute === 'leistungen'">
          <router-link
            :class="{
              active__router: subIsActive(['/'])
            }"
            to="/unternehmen"
            @click.native="closeSubNav"
            >Unternehmen
          </router-link>
          <router-link
            :class="{
              active__router: subIsActive(['/'])
            }"
            to="/stiftungen"
            @click.native="closeSubNav"
            >Stiftungen
          </router-link>
          <router-link
            :class="{
              active__router: subIsActive(['/'])
            }"
            to="/start-ups"
            @click.native="closeSubNav"
            >Start-ups
          </router-link>
          <router-link
            :class="{
              active__router: subIsActive(['/'])
            }"
            to="/privatpersonen"
            @click.native="closeSubNav"
            >Privatpersonen
          </router-link>
        </div>
        <div v-if="this.topRoute === 'menschen'">
          <router-link
            :class="{
              active__router: subIsActive(['/'])
            }"
            to="/partner"
            @click.native="closeSubNav"
            >Partner
          </router-link>
          <router-link
            :class="{
              active__router: subIsActive(['/'])
            }"
            to="/team"
            @click.native="closeSubNav"
            >Team
          </router-link>
          <router-link
            :class="{
              active__router: subIsActive(['/'])
            }"
            to="/gesundheit"
            @click.native="closeSubNav"
            >Gesundheit
          </router-link>
        </div>
        <div v-if="this.topRoute === 'karriere'">
          <router-link
            :class="{
              active__router: subIsActive(['/'])
            }"
            to="/partnerschaft"
            @click.native="closeSubNav"
            >Partnerschaft
          </router-link>
          <router-link
            :class="{
              active__router: subIsActive(['/'])
            }"
            to="/stellenangebote"
            @click.native="closeSubNav"
            >Stellenangebote
          </router-link>
          <router-link
            :class="{
              active__router: subIsActive(['/'])
            }"
            to="/weiterbildung"
            @click.native="closeSubNav"
            >Weiterbildung
          </router-link>
          <router-link
            :class="{
              active__router: subIsActive(['/'])
            }"
            to="/ihr-arbeitgeber"
            @click.native="closeSubNav"
            >Ihr Arbeitgeber
          </router-link>
        </div>
        <div
          v-if="this.topRoute === 'aktuelles'"
          style="  float: right;
"
        >
          <router-link
            :class="{
              active__router: subIsActive(['/'])
            }"
            to="/veroeffentlichungen"
            @click.native="closeSubNav"
            >Veröffentlichungen
          </router-link>
          <router-link
            :class="{
              active__router: subIsActive(['/'])
            }"
            to="/personelles"
            @click.native="closeSubNav"
            >Personelles
          </router-link>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
import variables from "/src/style/variables.scss";

export default {
  name: "Navigation",
  data() {
    return {
      announcementHeight: variables.announcementHeight,
      topRoute: null,
      subNavIsOpen: false,
      selectedTopRoute: "",
      topNav: document.getElementById("top_nav"),
      subNav: document.getElementById("sub__nav")
    };
  },
  watch: {},
  methods: {
    openNav() {
      this.topNav.style.display = "unset";
    },
    closeNav() {
      this.topNav.style.display = "none";
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some(path => {
        return this.$route.path === path;
      });
    },
    getAllTopRoutes() {
      return [
        document.getElementById("kanzlei"),
        document.getElementById("leistungen"),
        document.getElementById("menschen"),
        document.getElementById("karriere"),
        document.getElementById("aktuelles")
      ];
    },
    openSubNav(topRoute) {
      if (this.subNavIsOpen && topRoute === this.topRoute) {
        this.closeSubNav();
      } else {
        this.topRoute = topRoute;
        let topRoutes = this.getAllTopRoutes();
        topRoutes.forEach(element => (element.style.opacity = "0.3"));
        document.getElementById(topRoute.toString()).style.opacity = "1";
        document.getElementById("sub__nav").style.height = variables.navHeight;
        document.getElementById("sub__nav").style.borderBottom =
          "#2224b4 1px solid";

        document.getElementById("router__view").style.marginTop =
          "calc(" +
          variables.headerHeightExpanded +
          " + " +
          this.announcementHeight +
          ")";
        document.getElementById("head__id").style.height =
          variables.headerHeightExpanded;
        this.subNavIsOpen = true;
        this.selectedTopRoute = topRoute;
        document.getElementsByClassName("generic__site")[0].style.minHeight =
          "calc(100vh - 3.1rem - 3.9rem - 1rem - 3.1rem - " +
          this.announcementHeight +
          ")";
      }
    },
    closeSubNav() {
      if (this.$route.path === "/") {
        this.getAllTopRoutes().forEach(
          element => (element.style.opacity = "1")
        );
        document.getElementsByClassName("generic__site")[0].style.minHeight =
          "calc(100vh - 3.1rem - 3.9rem - 1rem )";
      }
      document.getElementsByClassName("generic__site")[0].style.minHeight =
        "calc(100vh - 3.1rem - 3.9rem - 1rem )";
      document.getElementById("sub__nav").style.height = "0";
      document.getElementById("sub__nav").style.borderBottom =
        "#2224b4 0px solid";
      document.getElementById("head__id").style.height = variables.headerHeight;
      this.subNavIsOpen = false;
      document.getElementById("router__view").style.marginTop =
        "calc(" +
        this.announcementHeight +
        " + " +
        variables.headerHeight +
        ")";
      this.$store.commit("setClickable", false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/style/variables";

#head__id {
  transition: 0.5s height;
  height: $headerHeight;
  width: 100vw;
  background: white;
  top: 0;
  position: fixed;
}

a {
  margin-top: $navElementPaddingTop;
  padding-right: $spaceBetweenElements;
  opacity: 0.3;
  color: $headlineColor;
  text-decoration: none;
  font-size: $headlineFontSize;
}

a.router-link-active,
.active__router {
  transition: 0.5s opacity;
  text-decoration: none;
  color: $headlineColor;
  opacity: 1;
  font-size: $headlineFontSize;
}

#top_nav {
  background: white;
  width: 100%;
  position: fixed;
  top: $topNavPosition;
  height: $navHeight;
  border-top: $headlineColor 1px solid;
}

#sub__nav {
  background: white;
  border-top: $headlineColor 1px solid;
  width: 100vw;
  position: fixed;
  top: $navHeight + $topNavPosition;
  height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: 0.5s height;
}

#sub__nav div a {
}

#sub__nav div {
  padding-top: $navElementPaddingTop;
}

nav {
  color: $headlineColor;
}

nav label {
  transition: 0.5s opacity;

  float: left;
  padding-top: $navElementPaddingTop;
  padding-right: $spaceBetweenElements;
  opacity: 0.3;
  font-size: $headlineFontSize;
}

nav label:nth-child(1),
nav a:nth-child(1) {
  padding-left: $paddingLeft;
}

nav label:nth-child(5) {
  float: right;
  padding-right: $paddingLeft;
}
</style>
