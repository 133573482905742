<template>
<Privatpersonen></Privatpersonen>
</template>

<script>
import Privatpersonen from "../../components/Leistungen/Privatpersonen";

export default {
components: {Privatpersonen}
}
</script>

<style scoped>

</style>