<template>
<Team></Team>
</template>

<script>
import Team from "../../components/Menschen/Team";

export default {
components: {Team}
}
</script>

<style scoped>

</style>