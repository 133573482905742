import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Startseite/Home";
import Personelles from "../views/Aktuelles/Personelles";
import Veroeffentlichungen from "../views/Aktuelles/Veroeffentlichungen";
import Kompetenz from "../views/Kanzlei/Kompetenz";
import Portraet from "../views/Kanzlei/Portraet";
import Haltung from "@/views/Kanzlei/Haltung";
import IhrArbeitgeber from "../views/Karriere/IhrArbeitgeber";
import Stellenangebote from "../views/Karriere/Stellenangebote";
import Weiterbildung from "../views/Karriere/Weiterbildung";
import Privatpersonen from "../views/Leistungen/Privatpersonen";
import StartUps from "../views/Leistungen/StartUps";
import Stiftungen from "../views/Leistungen/Stiftungen";
import Unternehmen from "../views/Leistungen/Unternehmen";
import Gesundheit from "../views/Menschen/Gesundheit";
import Partner from "../views/Menschen/Partner";
import Team from "../views/Menschen/Team";
import Datenschutz from "../views/Startseite/Datenschutz";
import Impressum from "../views/Startseite/Impressum";
import Kontakt from "../views/Startseite/Kontakt";
import Netzwerk from "../views/Startseite/Netzwerk";
import Partnerschaft from "../views/Karriere/Partnerschaft";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: Datenschutz
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: Impressum
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    component: Kontakt
  },
  {
    path: "/netzwerk",
    name: "Netzwerk",
    component: Netzwerk
  },
  {
    path: "/kompetenz",
    name: "Kompetenz",
    component: Kompetenz
  },
  {
    path: "/portraet",
    name: "Portraet",
    component: Portraet
  },
  {
    path: "/haltung",
    name: "Haltung",
    component: Haltung
  },
  {
    path: "/unternehmen",
    name: "Unternehmen",
    component: Unternehmen
  },
  {
    path: "/privatpersonen",
    name: "Privatpersonen",
    component: Privatpersonen
  },
  {
    path: "/start-ups",
    name: "Start-Ups",
    component: StartUps
  },
  {
    path: "/stiftungen",
    name: "Stiftungen",
    component: Stiftungen
  },
  {
    path: "/gesundheit",
    name: "Gesundheit",
    component: Gesundheit
  },
  {
    path: "/partner",
    name: "Partner",
    component: Partner
  },
  {
    path: "/team",
    name: "Team",
    component: Team
  },
  {
    path: "/personelles",
    name: "Personelles",
    component: Personelles
  },
  {
    path: "/veroeffentlichungen",
    name: "Veröffentlichungen",
    component: Veroeffentlichungen
  },
  {
    path: "/ihr-arbeitgeber",
    name: "Ihr Arbeitgeber",
    component: IhrArbeitgeber
  },
  {
    path: "/stellenangebote",
    name: "Stellenangebote",
    component: Stellenangebote
  },
  {
    path: "/weiterbildung",
    name: "Weiterbildung",
    component: Weiterbildung
  },
  {
    path: "/partnerschaft",
    name: "Partnerschaft",
    component: Partnerschaft
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
