<template>
  <div>
    <div class="generic__site">
      <Gallery></Gallery>
      <h2>Auch Privatpersonen sind bei uns in guten Händen</h2>
      <p>
        Wir beraten und begleiten Sie umfassend, wenn es um Ihre Steuern und Ihr
        Vermögen geht.
      </p>
      <p class="p__before__list">Wir</p>
      <ul class="list__after__p">
        <li><span>übernehmen für Sie die Steuererklärung und</span></li>
        <li>
          <span>
            kümmern uns um Ihre laufenden und einmaligen Erklärungspflichten,
          </span>
        </li>
        <li>
          <span>
            das heißt: Wir vertreten Sie gegenüber den Finanzbehörden in Sachen
            Einkommensteuererklärung oder Erbschaftsteuererklärung.
          </span>
        </li>
      </ul>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery";
import Footer from "@/components/Footer";

export default {
  name: "Privatpersonen",
  metaInfo: {
    title: 'Privatpersonen',
    titleTemplate: '%s | Kammers & Partner'
  },
  components: { Footer, Gallery }
};
</script>

<style scoped></style>
