<template>
<Partner></Partner>
</template>

<script>
import Partner from "../../components/Menschen/Partner";

export default {
components: {Partner}
}
</script>

<style scoped>

</style>