<template>
<Partnerschaft></Partnerschaft>
</template>

<script>
import Partnerschaft from "../../components/Karriere/Partnerschaft";

export default {
components: {Partnerschaft}
}
</script>

<style scoped>

</style>