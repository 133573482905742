<template>
  <div>
    <div class="generic__site">
      <Gallery></Gallery>
      <h2>Unsere Wurzeln, unser Wachstum</h2>
      <p>
        Bei KAMMERS & PARTNER Steuerberater Wirtschaftsprüfer Partnerschaft mbB 
        betreuen drei Partner und sechzehn Angestellte unsere nahezu tausend Mandate.
        Die erste Stunde unseres Büros geht auf die Gründung einer Einzelpraxis
        im Jahr 1927 zurück. Von Anfang an hat die Kanzlei ihren Sitz in
        Herbolzheim bei Freiburg im Breisgau.
      </p>
      <p class="highlight">Wir beraten auf den Punkt und leben Service und Mandantennähe</p>
      <p>
        Mit Gesprächspartnern und Behörden kommunizieren wir zukunftsweisend,
        verständlich und praxisnah. Auch wenn es bunt zugeht und komplex wird,
        gilt:
      </p>
      <p>
        Wir konzentrieren uns auf das Wesentliche.<br/>
        Wir reagieren schnell.<br/>
        Wir durchbrechen Routinen.<br/>
        Wir denken neu.
      </p>
      <p>Für Ihren Erfolg.</p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery";
import Footer from "@/components/Footer";

export default {
  name: "Portraet",
  metaInfo: {
    title: 'Portraet',
    titleTemplate: '%s | Kammers & Partner'
  },
  // eslint-disable-next-line vue/no-unused-components
  components: {Footer, Gallery}
};
</script>

<style scoped></style>
