<template>
<Unternehmen></Unternehmen>
</template>

<script>
import Unternehmen from "../../components/Leistungen/Unternehmen";

export default {
components:{Unternehmen}
}
</script>

<style scoped>

</style>