<template>
  <div>
    <div class="generic__site">
      <gallery></gallery>
      <h2>Das bewegt uns als Arbeitgeber</h2>
      <ul>
        <li><span>Den Mittelstand in der Region Südbaden zu stärken.</span></li>
        <li>
          <span>Die Menschen in der Kanzlei und Mandantschaft zu sehen.</span>
        </li>
        <li>
          <span>
            Flexible Arbeitszeiten, Teilzeit- und Homeoffice-Modelle zu
            ermöglichen.
          </span>
        </li>
        <li><span>Routinen zu durchbrechen.</span></li>
        <li><span>Steuerberatung neu zu denken.</span></li>
        <li><span>Wertschätzend zusammenzuarbeiten.</span></li>
        <li><span>Uns auf das Wesentliche zu konzentrieren.</span></li>
        <li>
          <span>
            Mitarbeiter zu entwickeln und aussichtsreiche Perspektiven zu
            gestalten.
          </span>
        </li>
        <li>
          <span
            >Hervorragende Leistungen überdurchschnittlich zu bezahlen.</span
          >
        </li>
        <li>
          <span>
            Unser attraktives Beratungsunternehmen zukunftsbejahend zu führen.
          </span>
        </li>
        <li>
          <span>
            Persönlichkeiten zu fördern, die sich aus eigenem Antrieb begeistern
            und engagieren.
          </span>
        </li>
      </ul>
      <p class="highlight">Was bewegt Sie?</p>
      <p>
        Wenn Sie entschlossen sind, persönlich und fachlich mit uns zu wachsen,
        dann sollten wir uns kennenlernen.         <a class="refs" href="mailto:info@kammers-partner.de"
      >Schreiben Sie uns.</a
      >
      </p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery";
import Footer from "@/components/Footer";

export default {
  name: "Ihr Arbeitgeber",
  metaInfo: {
    title: 'Ihr Arbeitgeber',
    titleTemplate: '%s | Kammers & Partner'
  },
  components: { Gallery, Footer }
};
</script>

<style scoped></style>
