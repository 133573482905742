<template>
<IhrArbeitgeber></IhrArbeitgeber>
</template>

<script>
import IhrArbeitgeber from "../../components/Karriere/IhrArbeitgeber";

export default {
components: {IhrArbeitgeber}
}
</script>

<style scoped>

</style>