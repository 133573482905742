<template>
  <div>
    <div class="generic__site">
      <h1>
        Verantwortlicher Redakteur und Webmaster für die Planung, Realisierung
        und Betreuung der Internetinhalte sowie für die Administration der
        Domain ist:
      </h1>

      <p>
        KAMMERS & PARTNER <br />
        Steuerberater Wirtschaftsprüfer <br />
        Partnerschaft mbB <br />
        Schwimmbadstraße 9 <br />
        79336 Herbolzheim <br />
        Telefon: 0 76 43 / 91 04 -0 <br />
        E-Mail:
        <a class="refs" href="mailto:info@kammers-partner.de"
          >info@kammers-partner.de</a
        >
        <br />
        USt.-IdNr.: DE 292453448
      </p>
      <p>
        Gestaltung: Jonas Kammers (<a
          class="refs"
          href="http://jonaskammers.com"
          >www.jonaskammers.com</a
        >) <br />
        Beratung und Programmierung: Pascal Lipschinski <br />
        Fotografie: Markus Edgar Ruf (<a class="refs" href="http://markusruf.de"
          >www.markusruf.de</a
        >) <br />
        Text: Susanne Kleiner (<a class="refs" href="http://susanne-kleiner.de"
          >www.susanne-kleiner.de</a
        >) <br />
      </p>
      <p>
        Die gesetzlichen Berufsbezeichnungen Steuerberater und Wirtschaftsprüfer
        wurden in der Bundesrepublik Deutschland (Bundesländer:
        Baden-Württemberg und Nordrhein-Westfalen) erworben bzw. verliehen.
      </p>
      <p>
        Die Partnerschaft ist beim Registergericht Freiburg PR 700164
        eingetragen.
      </p>
      <p class="highlight">Zuständige Aufsichtsbehörden:</p>
      <p>
        Steuerberaterkammer Südbaden <br />
        Wentzingerstraße 19 - 79106 Freiburg <br />
        Telefon: 0 761 / 70526-0<br />
        E-Mail:
        <a class="refs" href="mailto:info@stbk-suedbaden.de"
        >info@stbk-suedbaden.de</a
        >
        <br />
        Internet:<a class="refs" href="http://stbk-suedbaden.de">
        www.stbk-suedbaden.de</a
      >
      </p>
      <p>
        Wirtschaftsprüferkammer <br />
        Rauchstraße 26 - 10787 Berlin <br />
        Telefon: 0 30 / 72 61 61-0 <br />
        E-Mail: <a class="refs" href="mailto:kontakt@wpk.de">kontakt@wpk.de</a
      ><br />
        Internet: <a class="refs" href="http://wpk.de "> www.wpk.de </a> <br />
      </p>
      <p class="highlight">
        Der Berufstand der Steuerberater unterliegt im Wesentlichen den
        nachstehenden gesetzlichen Regelungen:
      </p>
      <ol>
        <li>Steuerberatungsgesetz (StBerG)</li>
        <li>Durchführungsverordnung zum Steuerberatungsgesetz (DVStB)</li>
        <li>Berufsordnung (BOStB)</li>
        <li>Steuerberatervergütungsverordnung (StBVV)</li>
      </ol>
      <p>
        Die berufsrechtlichen Regelungen können bei der zuständigen
        Steuerberaterkammer Südbaden eingesehen werden, diese finden Sie auch
        auf der Homepage der Bundessteuerberaterkammer (<a
          class="refs"
          href="http://bstbk.de"
      >www.bstbk.de</a
      >).
      </p>
      <p class="highlight">
        Der Berufstand der Wirtschaftsprüfer unterliegt im Wesentlichen den
        nachstehenden gesetzlichen Regelungen:
      </p>
      <ol>
        <li>Wirtschaftsprüferordnung (WPO)</li>
        <li>
          Berufssatzung für Wirtschaftsprüfer / vereidigte Buchprüfer (BS
          WP/vBP)
        </li>
        <li>Satzung für Qualitätskontrolle</li>
        <li>Wirtschaftsprüfer-Berufshaftpflichtversicherungsordnung</li>
      </ol>
      <p>
        Die berufsrechtlichen Regelungen können auf der Homepage der
        Wirtschaftsprüferkammer (<a class="refs" href="http://wpk.de"
      >www.wpk.de</a
      >) eingesehen werden.
      </p>
      <p class="highlight">Außergerichtliche Streitbeilegung</p>
      <p>
        Die Kanzlei KAMMERS & PARTNER Steuerberater Wirtschaftsprüfer
        Partnerschaft mbB ist weder verpflichtet, noch dazu bereit,
        an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
        teilzunehmen.
      </p>
      <p>
        Die Europäische Kommission stellt eine Plattform für die
        außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, die
        unter
        <a class="refs" href="http://ec.europa.eu/consumers/odr"
        >www.ec.europa.eu/consumers/odr</a
        >
        aufrufbar ist.
      </p>
      <p class="highlight">Berufshaftpflichtversicherung:</p>
      <p>
        Die Berufshaftpflichtversicherung besteht bei der Gothaer 
        Allgemeine Versicherung AG, 50598 Köln. Der räumliche
        Geltungsbereich des Versicherungsschutzes umfasst Tätigkeiten in den
        Mitgliedsländern der Europäischen Union und genügt so mindestens den
        Anforderungen der § 67 Steuerberatungsgesetz, §§ 51 ff. Verordnung zur
        Durchführung der Vorschriften über Steuerberater, Steuerbevollmächtigte
        und Steuerberatungsgesellschaften, § 54 Wirtschaftsprüferordnung in
        Verbindung über die Berufshaftpflichtversicherung der Wirtschaftsprüfer
        und vereidigten Buchprüfer.
      </p>
      <p> September 2024</p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "Impressum",
  metaInfo: {
    title: 'Impressum',
    titleTemplate: '%s | Kammers & Partner'
  },
  components: { Footer },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};
</script>

<style lang="scss" scoped>
@import "../../style/variables.scss";
</style>
