import { render, staticRenderFns } from "./Veroeffentlichungen.vue?vue&type=template&id=762bcd92&scoped=true&"
import script from "./Veroeffentlichungen.vue?vue&type=script&lang=js&"
export * from "./Veroeffentlichungen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "762bcd92",
  null
  
)

export default component.exports