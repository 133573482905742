<template>
  <div>
    <div class="generic__site">
      <gallery></gallery>

      <p>Sie sind</p>
      <p class="highlight">
        Steuerfachangestellte,<br> Steuerfachwirtin und/ oder Bilanzbuchhalterin (m/w/d)
      </p>
      <p>und bereit für Neues.<br />Gut, dass wir uns kennenlernen.</p>
      <p class="highlight">Das bewegt Sie</p>
      <ul>
        <li><span>Eigenverantwortlich und flexibel zu arbeiten.</span></li>
        <li>
          <span>Die Freiheit, auch im Homeoffice aktiv zu sein.</span>
        </li>
        <li><span>Gehört zu werden und zuzuhören.</span></li>
        <li>
          <span>Mit dem technischen Fortschritt engagiert mitzugehen.</span>
        </li>
        <li>
          <span
            >Überzeugend zu arbeiten und überdurchschnittlich zu
            verdienen.</span
          >
        </li>
      </ul>
      <p class="highlight">Das bewegt uns</p>
      <ul>
        <li><span>Den Mittelstand in der Region Südbaden zu stärken.</span></li>
        <li>
          <span>Routinen zu durchbrechen.</span>
        </li>
        <li>
          <span>Steuerberatung neu zu denken. </span>
        </li>
        <li>
          <span>Uns auf das Wesentliche zu konzentrieren. </span>
        </li>
        <li>
          <span
            >Mitarbeiter zu entwickeln und aussichtsreiche Perspektiven zu
            gestalten.
          </span>
        </li>
        <li>
          <span
            >Unser attraktives Beratungsunternehmen zukunftsbejahend zu führen.
          </span>
        </li>
        <li>
          <span>Persönlichkeiten wie Sie.</span>
        </li>
      </ul>
      <p>
        KAMMERS & PARTNER <br />
        Steuerberater Wirtschaftsprüfer <br>
        Partnerschaft mbB <br />
        Dr. Heinz Kammers<br />

        Schwimmbadstraße 9<br />

        79336 Herbolzheim<br />

        Telefon 07643 / 9104-0<br />

        <a class="refs" href="mailto:info@kammers-partner.de"
        >info@kammers-partner.de</a>


      </p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery";
import Footer from "@/components/Footer";

export default {
  name: "Stellenangebote",
  metaInfo: {
    title: 'Stellenangebote',
    titleTemplate: '%s | Kammers & Partner'
  },
  components: { Footer, Gallery }
};
</script>

<style scoped></style>
