<template>
  <div>
    <div class="generic__site">
      <Gallery></Gallery>
      <h2>
        Mut zur Veränderung
      </h2>
      <p>
        Wir entwickeln uns bewusst weiter. Und wir haben Freude daran, Neues zu
        bewegen. Chancen zu entdecken und aus Bewährtem zu lernen, ermutigt uns,
        anspruchsvoll vorwärtszugehen. Gute Ergebnisse und eine zufriedene
        Mandantschaft beflügeln uns. Für unser Team selbstverständlich: Wir
        formulieren, organisieren und realisieren gemeinsam Aufgaben rund um
        Fachwissen, Spezialisierung, Datensicherheit, Digitalisierung und
        Qualitätssicherung. Weil wir die Zukunft nicht dem Zufall überlassen.
      </p>
      <p class="highlight">
        Werte leben und Werte schöpfen
      </p>
      <p>
        Wir stehen für Expertise ohne Kompromisse. Dienstleistung im ureigenen
        Wortsinn als Dienst unter Freunden zu begreifen, beschreibt unsere
        Haltung. Wir vertrauen und kommunizieren offen. Selbst wenn Unangenehmes
        auf den Tisch kommt, sprechen wir Klartext; und zwar respektvoll.
        Vertrauen, Offenheit und Ehrlichkeit erwarten wir von uns selbst – und
        von unserem Gegenüber. Klar, dass wir alles daransetzen, damit Vorhaben
        gelingen. Mit Leidenschaft für die gemeinsame Sache machen wir uns dafür
        stark, das Beste zu erreichen.
      </p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery";
import Footer from "@/components/Footer";

export default {
  name: "Haltung",
  metaInfo: {
    title: 'Haltung',
    titleTemplate: '%s | Kammers & Partner'
  },
  components: { Gallery, Footer }
};
</script>

<style scoped></style>
