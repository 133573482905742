<template>
  <div>
    <div class="generic__site">
      <Gallery></Gallery>
      <h2>Miteinander und füreinander</h2>
      <p>
        Wir sind ein gewachsenes Team. Einige unserer Mitarbeiterinnen sind seit
        zehn, zwanzig, dreißig Jahren bei uns. Eine Kollegin hat sogar schon vor
        über fünfzig Jahre in dem Vorgängerbüro angefangen und ist bis
        heute begeistert dabei. Darauf sind wir besonders stolz.
      </p>
      <p>
        Gerade weil uns Teamgeist leitet,
        <router-link to="/stellenangebote" class="refs">
          heißen wir neue Kollegen herzlich willkommen.</router-link
        >
        Wir wissen: Auf jede und jeden Einzelnen kommt es an. Und wir erkennen:
        Es ist gut, Gewohntes in Frage zu stellen und Dialog zu führen.
      </p>
      <p>
        Bei aller Konsequenz am Schreibtisch legen wir Wert darauf, persönlich
        miteinander zu sprechen. Wenn wir einander in die Augen schauen, sind
        wir sensibler – auch für Zwischentöne, die Aufmerksamkeit verdienen.
        Dass wir unseren 'Runden Tisch' einmal im Monat etabliert haben und eine
        Kultur der „offenen“ Tür pflegen, drückt aus: Gute Arbeit braucht
        menschliche Begegnung.
      </p>
      <p class="highlight">Das bewegt uns Mitarbeiterinnen und Mitarbeiter</p>
      <ul>
        <li>
          <span>
            Wir arbeiten eigenverantwortlich und teilen uns unsere Arbeitszeiten
            frei ein.
          </span>
        </li>
        <li>
          <span>Wir fördern, fordern und unterstützen uns gegenseitig.</span>
        </li>
        <li>
          <span>
            Wir sind frei, im Büro, im Homeoffice oder vor Ort bei Mandanten zu
            arbeiten.
          </span>
        </li>
        <li><span>Wir sprechen über Fehler und denken in Lösungen.</span></li>
        <li>
          <span
            >Unsere Mandanten empfehlen uns weiter. Darauf sind wir stolz.
          </span>
        </li>
        <li>
          <span>
            Wir arbeiten im wahrsten Sinne des Wortes zusammen und überblicken
            die Mandate ganzheitlich.
          </span>
        </li>
        <li><span>Die Kanzleiinhaber stehen hinter uns.</span></li>
        <li>
          <span>Menschliche und fachliche Vorbilder motivieren uns.</span>
        </li>
        <li><span>Wir verdienen gut und das schätzen wir.</span></li>
      </ul>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery";
import Footer from "@/components/Footer";

export default {
  name: "Team",
  metaInfo: {
    title: 'Team',
    titleTemplate: '%s | Kammers & Partner'
  },
  components: { Gallery, Footer }
};
</script>

<style scoped></style>
