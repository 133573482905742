<template>
  <div>
    <div class="generic__site">
      <Gallery></Gallery>
      <h2>
        Aus einer Hand: Steuerberatung und Wirtschaftsprüfung.
      </h2>
      <p>
        Jeder Mensch ist eine Persönlichkeit mit individuellen Plänen und
        Idealen, privat und unternehmerisch. Richtungsweisend ist: Wer
        Zusammenhänge versteht und Kräfte bündelt, agiert vorausschauend und
        geht zielsicher voran.
      </p>
      <p>
        Und damit kennen wir uns aus. Von der Steuerberatung über die 
        betriebswirtschaftliche Begleitung bis hin zur Wirtschaftsprüfung. 
        Wir sind ausgewiesene Experten auf diesen Gebieten.
      </p>
      <p>
        Kleine und mittelständische
        <router-link to="/unternehmen" class="refs">Unternehmen</router-link>,
        <router-link to="/stiftungen" class="refs">Stiftungen</router-link>,
        <router-link to="/start-ups" class="refs">Start-ups</router-link>
        und
        <router-link to="/privatpersonen" class="refs"
          >Privatpersonen</router-link
        >
        vertrauen uns seit über dreißig Jahren. Und sie empfehlen uns weiter.
        Sie schätzen unsere Fachkompetenz und würdigen, dass wir Verantwortung
        für unser Handeln übernehmen. Viele heben die spürbare Wertschätzung und
        das freundliche Miteinander als besonders wertvoll hervor. Entscheidend
        für uns ist: Unsere Mandantinnen und Mandanten stehen im Mittelpunkt.
      </p>
      <p>
        <span class="highlight">
          Was bewegt Sie? <br />
          Was bewegen wir gemeinsam?</span
        >
      </p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery";
import Footer from "@/components/Footer";

export default {
  name: "Home",
  metaInfo: {
    title: "Home",
    titleTemplate: "%s | Kammers & Partner"
  },
  components: { Gallery, Footer },
  data() {
    return {};
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.unsetCss();
  },
  methods: {
    unsetCss() {
      document.getElementById("kanzlei").style.opacity = "1";
      document.getElementById("leistungen").style.opacity = "1";
      document.getElementById("menschen").style.opacity = "1";
      document.getElementById("karriere").style.opacity = "1";
      document.getElementById("aktuelles").style.opacity = "1";
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../style/variables.scss";
@media screen and (min-width: 2000px) {
  .marquee {
    span {
      animation: marquee 35s linear infinite !important;
    }
    span:hover {
      animation-play-state: paused !important;
    }
  }

  }
@media screen and (max-width: 780px) {
  .marquee {
    top: 3.6rem!important;
    font-family: Arnhem-Blond, serif;

  }
  .generic__site {
    padding-top: $announcementHeight + 1rem;
  }
}

.generic__site {
  min-height: calc(100vh - 3.1rem - 3.9rem - 3.1rem - #{$announcementHeight});
}

.marquee {
  position: fixed;
  background-color: $headlineColor;
  width: 100%;
  height: $announcementHeight;
  top: $topNavPosition;
  z-index: 0;
  max-width: 100vw;
  min-width: 100vw;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.5s;
  span {
    font-size: $headlineFontSize;
    color: #ffffff !important;
    display: inline-block;
    padding-top: 0.35rem;
    padding-left: 100%;
    animation: marquee 28s linear infinite;
    a {
      color: #ffffff !important;
    }
  }
  span:hover {
    animation-play-state: paused;
  }
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

#sub__nav div a {
  opacity: 1 !important;
}

</style>
