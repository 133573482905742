<template>
<Netzwerk></Netzwerk>
</template>

<script>
import Netzwerk from "../../components/Startseite/Netzwerk";

export default {
components: {Netzwerk}
}
</script>

<style scoped>

</style>