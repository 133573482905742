<template>
<veroeffentlichungen></veroeffentlichungen>
</template>

<script>

import Veroeffentlichungen from "../../components/Aktuelles/Veroeffentlichungen";

export default {
  components: {Veroeffentlichungen}
}
</script>

<style scoped>

</style>