<template>
  <div>
    <transition mode="out-in">

    <Navigation id="nav"></Navigation>
    </transition>

    <transition mode="out-in">
      <router-view id="router__view" :class="this.$route.path === '/' ? 'is__home' : 'is__not__home'"/>
    </transition>
  </div>
</template>
<script>
import Navigation from "@/views/Navigation";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Navigation
  },
  data() {
    return {
      home : this.$route.path === '/'
    }
  }
  }
</script>
<style lang="scss">
@import "./style/variables.scss";

#nav {
  transition: 0.5s;
}

#router__view {
  transition: 0.5s;
}
.is__home {
  margin-top: $navHeight + $topNavPosition + $announcementHeight;
}
.is__not__home {
  margin-top: $navHeight + $topNavPosition;
}
</style>
