<template>
  <div>
    <header id="head__id">
      <div style="height: 3.6rem ">
        <router-link to="/" class="headline">KAMMERS & PARTNER</router-link>
      </div>
      <div id="burger__menu__resp">
        <img
          v-if="navIsOpen"
          class="icon"
          src="../assets/cross.png"
          @click="closeNav"
        />
        <img v-else class="icon" src="../assets/burger.png" @click="openNav" />
        <div id="burger__content">
          <nav id="top_nav__resp">
            <label style="opacity: 0;">asd </label>
            <label id="kanzlei__resp" @click="openSubNav('kanzlei__resp')"
              >Kanzlei
            </label>

            <transition-expand>
              <div class="sub" v-if="this.topRoute === 'kanzlei__resp'">

                <router-link to="/portraet" @click.native="closeNav"
                  >Porträt</router-link
                >
                <router-link to="/haltung" @click.native="closeNav"
                  >Haltung</router-link
                >
                <router-link to="/kompetenz" @click.native="closeNav"
                >Kompetenz
                </router-link>
              </div>
            </transition-expand>
            <label id="leistungen__resp" @click="openSubNav('leistungen__resp')"
              >Leistung</label
            >
            <transition-expand>
              <div class="sub" v-if="this.topRoute === 'leistungen__resp'">
                <router-link to="/unternehmen" @click.native="closeNav"
                  >Unternehmen
                </router-link>
                <router-link to="/stiftungen" @click.native="closeNav"
                  >Stiftungen</router-link
                >
                <router-link to="/start-ups" @click.native="closeNav"
                  >Start-ups</router-link
                >
                <router-link to="/privatpersonen" @click.native="closeNav"
                  >Privatpersonen</router-link
                >
              </div>
            </transition-expand>

            <label id="menschen__resp" @click="openSubNav('menschen__resp')"
              >Menschen</label
            >
            <transition-expand>
              <div class="sub" v-if="this.topRoute === 'menschen__resp'">
                <router-link to="/partner" @click.native="closeNav"
                  >Partner
                </router-link>
                <router-link to="/team" @click.native="closeNav"
                  >Team</router-link
                >
                <router-link to="/gesundheit" @click.native="closeNav"
                  >Gesundheit</router-link
                >
              </div>
            </transition-expand>

            <label id="karriere__resp" @click="openSubNav('karriere__resp')"
              >Karriere</label
            >
            <transition-expand>
              <div class="sub" v-if="this.topRoute === 'karriere__resp'">
                <router-link to="/partnerschaft" @click.native="closeNav"
                  >Partnerschaft
                </router-link>
                <router-link to="/stellenangebote" @click.native="closeNav"
                  >Stellenangebote</router-link
                >
                <router-link to="/weiterbildung" @click.native="closeNav"
                  >Weiterbildung</router-link
                >
                <router-link to="/ihr-arbeitgeber" @click.native="closeNav"
                  >Ihr Arbeitgeber</router-link
                >
              </div>
            </transition-expand>

            <label id="aktuelles__resp" @click="openSubNav('aktuelles__resp')"
              >Aktuelles</label
            >
            <transition-expand>
              <div class="sub" v-if="this.topRoute === 'aktuelles__resp'">
                <router-link to="/veroeffentlichungen" @click.native="closeNav"
                  >Veröffentlichungen
                </router-link>
                <router-link to="/personelles" @click.native="closeNav"
                  >Personelles</router-link
                >
              </div>
            </transition-expand>

            <!--              <router-link to="/netzwerk" @click.native="closeNav"
                >Netzwerk</router-link
              >
              <router-link to="/datenschutz" @click.native="closeNav"
                >Datenschutz</router-link
              >-->
          </nav>
        </div>

        <footer id="footer">
          <router-link to="/netzwerk" @click.native="closeNav"
            >Netzwerk</router-link
          >
          <router-link to="/kontakt" @click.native="closeNav"
            >Kontakt</router-link
          >
          <router-link to="/datenschutz" @click.native="closeNav"
            >Datenschutz</router-link
          >
          <router-link to="/impressum" @click.native="closeNav"
            >Impressum</router-link
          >
          <span>&copy; 2021</span>
        </footer>
      </div>
    </header>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import variables from "/src/style/variables.scss";
import TransitionExpand from "@/components/TransitionExpand";
export default {
  name: "Navigation",
  components: { TransitionExpand },
  data() {
    return {
      TransitionExpand,
      topRoute: null,
      navIsOpen: false,
      subNavIsOpen: false,
      selectedTopRoute: "",
      topNav: document.getElementById("top_nav__resp"),
      subNav: document.getElementsByClassName("sub__nav__resp")[0]
    };
  },
  watch: {},
  created() {},
  methods: {
    openNav() {
      if (this.$route.path === "/") {
        this.closeSubNav();

        this.getAllTopRoutes().forEach(
          element => (element.style.color = "rgba(34, 37, 180,1)")
        );
      }
      document.getElementById("router__view").style.display = "none";
      document.getElementById("burger__menu__resp").style.width = "100vw";
      document.getElementById("burger__menu__resp").style.height = "100vh";
      document.getElementById("top_nav__resp").style.visibility = "visible";
      document.getElementById("top_nav__resp").style.opacity = "1";
      document.getElementById("footer").style.visibility = "visible";
      document.getElementById("footer").style.opacity = "1";
      document.getElementById("footer").style.width = "100vw";
      this.navIsOpen = true;
    },
    closeNav() {
      document.getElementById("burger__menu__resp").style.width = "0";
      document.getElementById("top_nav__resp").style.visibility = "hidden";
      document.getElementById("footer").style.visibility = "hidden";
      document.getElementById("footer").style.opacity = "0";
      document.getElementById("top_nav__resp").style.opacity = "0";
      /*      this.getAllTopRoutes().forEach(
        element => (element.style.color = "rgba(34, 37, 180,1)")
      );*/
      document.getElementById("router__view").style.display = "block";

      this.navIsOpen = false;
      //      this.topRoute = null;
    },
    getAllTopRoutes() {
      return [
        document.getElementById("kanzlei__resp"),
        document.getElementById("leistungen__resp"),
        document.getElementById("menschen__resp"),
        document.getElementById("karriere__resp"),
        document.getElementById("aktuelles__resp")
      ];
    },
    openSubNav(topRoute) {
      if (this.subNavIsOpen && topRoute === this.topRoute) {
        this.closeSubNav(topRoute);
      } else {
        this.topRoute = topRoute;
        this.getAllTopRoutes().forEach(
          element => (element.style.color = "rgba(34, 37, 180,0.3)")
        );
        document.getElementById(topRoute).style.color = "rgba(34, 37, 180,1)";
        this.subNavIsOpen = true;
        this.selectedTopRoute = topRoute;
      }
    },
    closeSubNav() {
      this.getAllTopRoutes().forEach(
        element => (element.style.color = "rgba(34, 37, 180,1)")
      );
      this.topRoute = null;
      this.subNavIsOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/style/variables";

@media screen and (max-width: 780px) {
  #kanzlei__resp {
    border-top: 1px solid $headlineColor;
  }
  .sub {
    display: grid;
    padding-left: 1rem;
  }
  #burger__content {
    transition: 0.5s;
    visibility: hidden;
    min-height: calc(100vh - 13.5rem);
  }
  #top_nav__resp label {
    font-family: Arnhem-Blond, serif;

    background: rgba(255, 255, 255, 1) !important;
    border-bottom: 1px solid $headlineColor;
    padding-top: 2rem;
    padding-bottom: 0.2rem;
    padding-left: 1rem;
  }
  #top_nav__resp {
    transition: 0.5s;
    display: grid;
    visibility: hidden;
    opacity: 0;
    z-index: 100;
    background: white;
  }
  #footer {
    transition: 0.5s linear;
    opacity: 0;
  }
  footer {
    font-family: Arnhem-Blond, serif;

    padding-top: 4rem;
    visibility: hidden;
    display: grid;
    padding-bottom: 2rem;
  }
  footer span,
  footer a,
  footer a.router-link-active,
  footer .active__router {
    margin-top: 0;
    margin-bottom: 0.5rem;
    padding-left: 1rem;
    color: $headlineColor;
    font-size: 0.95rem;
  }
  .sub__nav__resp {
    display: grid;
    padding-left: 1rem;
  }
  #burger__menu__resp {
    position: absolute;
    width: 0;
    top: 0;
    transition: width 0.5s linear;
    z-index: 100;
    bottom: 0;
    height: 100%;
    background: #ffff;
  }
  a {
    margin-top: $navElementPaddingTop;
    opacity: 0.3;
    font-family: Arnhem-Blond, serif;

    color: $headlineColor;
    text-decoration: none;
    font-size: $headlineFontSize;
  }
  a.router-link-active,
  .active__router {
    font-family: Arnhem-Blond, serif;

    text-decoration: none;
    color: $headlineColor;
    opacity: 1;
    font-size: $headlineFontSize;
  }
  #sub__nav__resp div a {
    transition: 0.5s;
    font-family: Arnhem-Blond, serif;

    opacity: 1 !important;
  }
  nav {
    transition: 0.5s;
    color: $headlineColor;
  }
  nav label {
    transition: 0.5s;
    padding-top: $navElementPaddingTop;
    padding-right: $spaceBetweenElements;
    font-size: $headlineFontSize;
  }

  .icon {
    z-index: 100;
    position: absolute;
    padding-top: calc(#{$headlinePaddingTop} + 0.3rem);
    padding-left: $paddingLeftResponsive;
    width: 1.5rem;
    height: 0.9rem;
  }
}
</style>
