import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "normalize.css";
import "@/main.scss";
import vuescroll from "vuescroll";
import Meta from "vue-meta"

Vue.use(vuescroll);
Vue.use(Meta)
Vue.config.productionTip = false;

new Vue({ router, store, render: h => h(App) }).$mount("#app");
