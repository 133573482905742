<template>
<Stiftungen></Stiftungen>
</template>

<script>
import Stiftungen from "../../components/Leistungen/Stiftungen";

export default {
components: {Stiftungen}
}
</script>

<style scoped>

</style>