<template>
<stellenangebote></stellenangebote>
</template>

<script>
import Stellenangebote from "../../components/Karriere/Stellenangebote";

export default {
components: {Stellenangebote}
}
</script>

<style scoped>

</style>