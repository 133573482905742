<template>
<StartUps></StartUps>
</template>

<script>
import StartUps from "../../components/Leistungen/StartUps";

export default {
components: {StartUps}
}
</script>

<style scoped>

</style>