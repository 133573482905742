<template>
<personelles></personelles>
</template>

<script>
import Personelles from "../../components/Aktuelles/Personelles";

export default {
  components: {Personelles}
}
</script>

<style scoped>

</style>