<template>
  <div>
    <div class="generic__site">
      <gallery></gallery>
      <h2>
        Unsere Erfahrungen sind weitreichend und profund
      </h2>
      <p>
        Unser Engagement ist kraftvoll. Wir beraten fachlich erstklassig und
        umfassend in allen steuerlichen und wirtschaftlichen
        Fragen. Als erfolgsentscheidend haben sich unsere klaren Strukturen im
        Arbeitsprozess und in direkten persönlichen Zuständigkeiten bewährt.
        Zwei verlässliche und dauerhafte Kontaktpersonen, eine Mitarbeiterin
        und einer der Partner, begleiten Sie vertrauensvoll.
      </p>
      <p class="highlight">Unser Konzept ist ganzheitlich</p>
      <p>
        Wer mit uns zusammenarbeitet, profitiert von unseren Kompetenzen.
        In der Steuerberatung und Wirtschaftsprüfung
        arbeiten wir vernetzt. Wir nutzen kurze Wege und handeln effizient und
        zügig. Besonders in der Gestaltungsberatung hat es sich als chancenreich
        bewährt, „das Ganze" im Blick zu haben.
      </p>
      <p>
        Und wir arbeiten in einem interprofessionellen Netzwerk. So stellen wir
        beispielsweise sicher, dass europa- oder weltweit relevante Projekte mit
        dem Rat international erfahrener Berufskollegen und Notare eine
        erfolgversprechende Reife erlangen.
      </p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery";
import Footer from "@/components/Footer";

export default {
  name: "Kompetenz",
  metaInfo: {
    title: 'Kompetenz',
    titleTemplate: '%s | Kammers & Partner'
  },
  components: { Gallery, Footer }
};
</script>

<style scoped></style>
