<template>
  <div>
    <div class="generic__site">
      <h1>Wir arbeiten vernetzt</h1>
      <p class="highlight">Beratungspartner</p>
      <p>
        Bei Bedarf, vor allem für internationale Mandate, arbeiten wir seit
        vielen Jahren vertrauensvoll mit der Grant Thornton AG (<a
          class="refs"
          href="http://grantthornton.de"
          >www.grantthornton.de</a
        >) zusammen. Die Grant Thornton AG gehört zu den führenden
        mittelständischen Wirtschaftsprüfungsgesellschaften in Deutschland.
      </p>
      <p class="highlight">Systempartner</p>
      <p>
        Systempartner für unsere EDV ist Carsten Friedrich (<a
          class="refs"
          href="http://frietech.de"
          >www.frietech.de</a
        >). Er leistet für uns die komplette Systembetreuung – zuverlässig und
        direkt vor Ort. Mit Carsten Friedrich verbindet uns eine
        vertrauensvolle, effektive und zuverlässige Zusammenarbeit.
      </p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "Netzwerk",
  metaInfo: {
    title: 'Netzwerk',
    titleTemplate: '%s | Kammers & Partner'
  },
  components: { Footer },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};
</script>

<style lang="scss" scoped>
@import "../../style/variables.scss";
</style>
