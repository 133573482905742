<template>
<Kontakt></Kontakt>
</template>

<script>
import Kontakt from "../../components/Startseite/Kontakt";

export default {
components: {Kontakt}
}
</script>

<style scoped>

</style>