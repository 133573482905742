import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    clickable: true,
    images: {
      startseite: [
        {
          id: 0,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Kammers%26Partner-44.jpg",
          thumbnail: require("@/assets/thumbnail_Kammers&Partner-44.jpg"),
          format: "normal"
        },
        {
          id: 1,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Kammers%26Partner-31.jpg",
          thumbnail: require("@/assets/thumbnail_Kammers&Partner-31.jpg"),
          format: "normal"
        },
        {
          id: 2,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Kammers%26Partner-43.jpg",
          thumbnail: require("@/assets/thumbnail_Kammers&Partner-43.jpg"),
          format: "normal"
        },
        {
          id: 3,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Kammers%26Partner-7.jpg",
          thumbnail: require("@/assets/thumbnail_Kammers&Partner-7.jpg"),
          format: "upright"
        },
        {
          id: 4,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Kammers%26Partner-39.jpg",
          thumbnail: require("@/assets/thumbnail_Kammers&Partner-39.jpg"),
          format: "normal"
        }
      ],
      kanzlei: [
        {
          id: 0,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Kammers%26Partner-41.jpg",
          thumbnail: require("@/assets/thumbnail_Kammers&Partner-41.jpg"),
          format: "normal"
        },
        {
          id: 1,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Kammers%26Partner-46(1).jpg",
          thumbnail: require("@/assets/thumbnail_Kammers&Partner-46(1).jpg"),
          format: "normal"
        },
        {
          id: 2,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Kammers%26Partner-60.jpg",
          thumbnail: require("@/assets/thumbnail_Kammers&Partner-60.jpg"),
          format: "normal"
        }
      ],
      leistungen: [
        {
          id: 0,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Kammers%26Partner-42.jpg",
          thumbnail: require("@/assets/thumbnail_Kammers&Partner-42.jpg"),
          format: "normal"
        },
        {
          id: 1,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Kammers%26Partner-54.jpg",
          thumbnail: require("@/assets/thumbnail_Kammers&Partner-54.jpg"),
          format: "normal"
        }
      ],
      partner: [
        {
          id: 0,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Kammers.jpg",
          thumbnail: require("@/assets/thumbnail_Kammers.jpg"),
          format: "upright"
        },
        {
          id: 1,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Herr%20Uhle.jpg",
          thumbnail: require("@/assets/thumbnail_Herr Uhle.jpg"),
          format: "upright"
        },
        {
          id: 2,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/St%C3%B6hr.jpg",
          thumbnail: require("@/assets/thumbnail_Stöhr.jpg"),
          format: "upright"
        }
      ],
      team: [
        {
          id: 0,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Kammers%26Partner-32.jpg",
          thumbnail: require("@/assets/thumbnail_Kammers&Partner-32.jpg"),
          format: "normal"
        },
        {
          id: 1,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Kammers%26Partner-38.jpg",
          thumbnail: require("@/assets/thumbnail_Kammers&Partner-38.jpg"),
          format: "normal"
        },
        {
          id: 2,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Kammers%26Partner-61.jpg",
          thumbnail: require("@/assets/thumbnail_Kammers&Partner-61.jpg"),
          format: "normal"
        }
      ],
      karriere: [
        {
          id: 0,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Kammers%26Partner-51.jpg",
          thumbnail: require("@/assets/thumbnail_Kammers&Partner-51.jpg"),
          format: "normal"
        },

        {
          id: 1,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Kammers%26Partner-59.jpg",
          thumbnail: require("@/assets/thumbnail_Kammers&Partner-59.jpg"),
          format: "upright"
        },
        {
          id: 2,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Kammers%26Partner-34.jpg",
          thumbnail: require("@/assets/thumbnail_Kammers&Partner-34.jpg"),
          format: "normal"
        }
      ],
      kontakt: [
        {
          id: 0,
          image: "https://filedn.eu/lCpNMn0n3Q3QqocDP8Qdazb/kammers-partner/Kammers%26Partner-40.jpg",
          thumbnail: require("@/assets/thumbnail_Kammers&Partner-40.jpg"),
          format: "normal"
        }
      ]
    }
  },
  mutations: {
    setClickable(state, clickable) {
      state.clickable = clickable;
    }
  },
  actions: {},
  modules: {},
  getters: {
    startseiteImages: state => state.images.startseite,
    kanzleiImages: state => state.images.kanzlei,
    leistungenImages: state => state.images.leistungen,
    partnerImages: state => state.images.partner,
    teamImages: state => state.images.team,
    karriereImages: state => state.images.karriere,
    kontaktImages: state => state.images.kontakt,
    clickable: state => state.clickable
  }
});
