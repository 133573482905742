<template>
  <div>
    <div class="generic__site">
      <gallery></gallery>
      <h2>
        Wir wachsen. Am liebsten gemeinsam mit Ihnen. Als Partnerin oder Partner
        unserer Kanzlei.
      </h2>
      <p>
        Sie sind Steuerberaterin und Wirtschaftsprüferin (m/w/d) und so erfahren
        wie anspruchsvoll. </p> <p>Für Sie ist klar: Ihr nächster Karriereschritt führt
        in die Selbstständigkeit – und zwar an einem attraktiven Standort mit
        hoher Lebensqualität.
        Dann passen wir gut zusammen. Kurzum: Wir wollen Sie kennenlernen.</p>
      <p class="highlight">Das bringen Sie mit</p>
      <ul>
        <li>
          <span>Sie sind eine starke Persönlichkeit und teamfähig. </span>
        </li>
        <li>
          <span
            >Sie verstehen Führung als Vorbildfunktion und fördern
            Eigenverantwortung.</span
          >
        </li>
        <li>
          <span
            >Sie arbeiten zielgerichtet und begegnen Unvorhergesehenem
            standhaft.</span
          >
        </li>
        <li>
          <span
            >Sie hören zu, sprechen klare Worte und handeln mit
            Fingerspitzengefühl.</span
          >
        </li>
        <li>
          <span
            >Sie denken unternehmerisch und übernehmen Verantwortung - für sich
            und andere.</span
          >
        </li>
        <li>
          <span
            >Sie kombinieren Leichtigkeit mit Leistung und lieben das
            Leben.</span
          >
        </li>
        <li>
          <span
            >Sie schätzen Lebensqualität im Privaten und legen Wert auf eine
            gute Balance zum Beruf.</span
          >
        </li>
      </ul>
      <p class="highlight">Das erwartet Sie hier</p>

      <ul>
        <li>
          <span
            >Eine gewachsene, gesunde Kanzlei in einer Region, wo der
            Mittelstand zuhause ist.</span
          >
        </li>
        <li>
          <span
            >Fünfzehn treue und engagierte Mitarbeiterinnen, die Augenhöhe gewohnt
            sind.</span
          >
        </li>
        <li>
          <span
            >        <a
              class="refs"
              style="font-style: italic"
              href="./DStR_20_2021_ASP_ IT-Special_Kammers.pdf"
              target="_blank"
          >Eine Datev-zertifizierte digitale Kanzlei</a
          >, die mit ihrer
            Mandantschaft wächst.</span
          >
        </li>
        <li>
          <span
            >Ein Miteinander, das Spaß macht. Ein Füreinander, das trägt.</span
          >
        </li>
        <li>
          <span
            >Wir leben von Weiterempfehlungen und wir leben gut. In der
            Genussregion Südbaden. Mit dem Schwarzwald vor der Haustür.</span
          >
        </li>
      </ul>
      <p>
        Machen Sie den nächsten Schritt und kontaktieren Sie uns. Alles andere
        besprechen wir persönlich.
      </p>
      <p>
        KAMMERS & PARTNER <br />
        Steuerberater Wirtschaftsprüfer <br />
        Partnerschaft mbB <br>
        Dr. Heinz Kammers <br />
        Schwimmbadstraße 9 <br />
        79336 Herbolzheim <br />
        Telefon 07643 / 9104-0 <br />
        <a class="refs" href="mailto:info@kammers-partner.de"
        >info@kammers-partner.de</a>

      </p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery";
import Footer from "@/components/Footer";

export default {
  name: "Partnerschaft",
  metaInfo: {
    title: 'Partnerschaft',
    titleTemplate: '%s | Kammers & Partner'
  },
  components: { Footer, Gallery }
};
</script>

<style scoped></style>
