<template>
<Portraet></Portraet>
</template>

<script>
import Portraet from "../../components/Kanzlei/Portraet";

export default {
components: {Portraet}
}
</script>

<style scoped>

</style>