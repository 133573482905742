var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{attrs:{"id":"head__id"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('router-link',{staticClass:"headline",staticStyle:{"font-size":"1.3rem","font-weight":"200","letter-spacing":"0.1rem!important"},attrs:{"id":"headr","to":"/"},nativeOn:{"click":function($event){return _vm.closeSubNav.apply(null, arguments)}}},[_vm._v("KAMMERS & PARTNER ")])],1),_c('nav',{attrs:{"id":"top_nav"}},[_c('label',{class:{
            active__router: _vm.subIsActive([
              '/kompetenz',
              '/portraet',
              '/haltung',
              '/'
            ])
          },attrs:{"id":"kanzlei"},on:{"click":function($event){return _vm.openSubNav('kanzlei')}}},[_vm._v("Kanzlei")]),_c('label',{class:{
            active__router: _vm.subIsActive([
              '/unternehmen',
              '/stiftungen',
              '/start-ups',
              '/privatpersonen',
              '/'
            ])
          },attrs:{"id":"leistungen"},on:{"click":function($event){return _vm.openSubNav('leistungen')}}},[_vm._v("Leistungen")]),_c('label',{class:{
            active__router: _vm.subIsActive([
              '/partner',
              '/team',
              '/gesundheit',
              '/'
            ])
          },attrs:{"id":"menschen"},on:{"click":function($event){return _vm.openSubNav('menschen')}}},[_vm._v("Menschen")]),_c('label',{class:{
            active__router: _vm.subIsActive([
              '/partnerschaft',
              '/stellenangebote',
              '/weiterbildung',
              '/ihr-arbeitgeber',
              '/'
            ])
          },attrs:{"id":"karriere"},on:{"click":function($event){return _vm.openSubNav('karriere')}}},[_vm._v("Karriere")]),_c('label',{class:{
            active__router: _vm.subIsActive([
              '/veroeffentlichungen',
              '/personelles',
              '/'
            ])
          },attrs:{"id":"aktuelles"},on:{"click":function($event){return _vm.openSubNav('aktuelles')}}},[_vm._v("Aktuelles")])]),_c('nav',{attrs:{"id":"sub__nav"}},[(this.topRoute === 'kanzlei')?_c('div',[_c('router-link',{class:{
              active__router: _vm.subIsActive(['/'])
            },attrs:{"to":"/portraet"},nativeOn:{"click":function($event){return _vm.closeSubNav.apply(null, arguments)}}},[_vm._v("Porträt ")]),_c('router-link',{class:{
              active__router: _vm.subIsActive(['/'])
            },attrs:{"to":"/haltung"},nativeOn:{"click":function($event){return _vm.closeSubNav.apply(null, arguments)}}},[_vm._v("Haltung ")]),_c('router-link',{class:{
              active__router: _vm.subIsActive(['/'])
            },attrs:{"to":"/kompetenz"},nativeOn:{"click":function($event){return _vm.closeSubNav.apply(null, arguments)}}},[_vm._v("Kompetenz ")])],1):_vm._e(),(this.topRoute === 'leistungen')?_c('div',[_c('router-link',{class:{
              active__router: _vm.subIsActive(['/'])
            },attrs:{"to":"/unternehmen"},nativeOn:{"click":function($event){return _vm.closeSubNav.apply(null, arguments)}}},[_vm._v("Unternehmen ")]),_c('router-link',{class:{
              active__router: _vm.subIsActive(['/'])
            },attrs:{"to":"/stiftungen"},nativeOn:{"click":function($event){return _vm.closeSubNav.apply(null, arguments)}}},[_vm._v("Stiftungen ")]),_c('router-link',{class:{
              active__router: _vm.subIsActive(['/'])
            },attrs:{"to":"/start-ups"},nativeOn:{"click":function($event){return _vm.closeSubNav.apply(null, arguments)}}},[_vm._v("Start-ups ")]),_c('router-link',{class:{
              active__router: _vm.subIsActive(['/'])
            },attrs:{"to":"/privatpersonen"},nativeOn:{"click":function($event){return _vm.closeSubNav.apply(null, arguments)}}},[_vm._v("Privatpersonen ")])],1):_vm._e(),(this.topRoute === 'menschen')?_c('div',[_c('router-link',{class:{
              active__router: _vm.subIsActive(['/'])
            },attrs:{"to":"/partner"},nativeOn:{"click":function($event){return _vm.closeSubNav.apply(null, arguments)}}},[_vm._v("Partner ")]),_c('router-link',{class:{
              active__router: _vm.subIsActive(['/'])
            },attrs:{"to":"/team"},nativeOn:{"click":function($event){return _vm.closeSubNav.apply(null, arguments)}}},[_vm._v("Team ")]),_c('router-link',{class:{
              active__router: _vm.subIsActive(['/'])
            },attrs:{"to":"/gesundheit"},nativeOn:{"click":function($event){return _vm.closeSubNav.apply(null, arguments)}}},[_vm._v("Gesundheit ")])],1):_vm._e(),(this.topRoute === 'karriere')?_c('div',[_c('router-link',{class:{
              active__router: _vm.subIsActive(['/'])
            },attrs:{"to":"/partnerschaft"},nativeOn:{"click":function($event){return _vm.closeSubNav.apply(null, arguments)}}},[_vm._v("Partnerschaft ")]),_c('router-link',{class:{
              active__router: _vm.subIsActive(['/'])
            },attrs:{"to":"/stellenangebote"},nativeOn:{"click":function($event){return _vm.closeSubNav.apply(null, arguments)}}},[_vm._v("Stellenangebote ")]),_c('router-link',{class:{
              active__router: _vm.subIsActive(['/'])
            },attrs:{"to":"/weiterbildung"},nativeOn:{"click":function($event){return _vm.closeSubNav.apply(null, arguments)}}},[_vm._v("Weiterbildung ")]),_c('router-link',{class:{
              active__router: _vm.subIsActive(['/'])
            },attrs:{"to":"/ihr-arbeitgeber"},nativeOn:{"click":function($event){return _vm.closeSubNav.apply(null, arguments)}}},[_vm._v("Ihr Arbeitgeber ")])],1):_vm._e(),(this.topRoute === 'aktuelles')?_c('div',{staticStyle:{"float":"right"}},[_c('router-link',{class:{
              active__router: _vm.subIsActive(['/'])
            },attrs:{"to":"/veroeffentlichungen"},nativeOn:{"click":function($event){return _vm.closeSubNav.apply(null, arguments)}}},[_vm._v("Veröffentlichungen ")]),_c('router-link',{class:{
              active__router: _vm.subIsActive(['/'])
            },attrs:{"to":"/personelles"},nativeOn:{"click":function($event){return _vm.closeSubNav.apply(null, arguments)}}},[_vm._v("Personelles ")])],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }