<template>
  <div>
    <div class="generic__site">
      <Gallery></Gallery>
      <p>
        KAMMERS & PARTNER <br />
        Steuerberater Wirtschaftsprüfer <br />
        Partnerschaft mbB <br />
        Schwimmbadstraße 9 <br />
        79336 Herbolzheim
      </p>
      <p>
        Telefon: 0 76 43 / 91 04 -0 <br />
        E-Mail:
        <a class="refs" href="mailto:info@kammers-partner.de"
          >info@kammers-partner.de</a
        >
      </p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery";
import Footer from "@/components/Footer";

export default {
  name: "Kontakt",
  metaInfo: {
    title: 'Kontakt',
    titleTemplate: '%s | Kammers & Partner'
  },
  components: { Gallery, Footer },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};
</script>

<style lang="scss" scoped>
@import "../../style/variables.scss";
</style>
