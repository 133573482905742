<template>
  <div>
    <div class="generic__site">
      <gallery></gallery>

      <h2>Wir arbeiten gerne. Und wir arbeiten an uns. Aus Überzeugung.</h2>
      <p>
        Wir sind davon überzeugt, dass jeder Mensch Stärken und Talente in sich
        trägt, die förderungswürdig sind. Und wir haben Spaß daran, am Puls der
        Zeit zu agieren. Deshalb bilden wir uns konsequent weiter: fachlich mit
        Online-Seminaren, die einen festen Platz im Kalenderjahr einnehmen. Und
        mit Präsenzseminaren oder Veranstaltungen, die spezielle Themen im
        Detail behandeln. Hier geht es zum Beispiel um
      </p>
      <ul>
        <li><span>Lohn und Gehalt,</span></li>
        <li><span>Gesetzesänderungen,</span></li>
        <li><span>Rechtsprechung,</span></li>
        <li>
          <span>Besonderheiten von Kapitalgesellschaften und Stiftungen,</span>
        </li>
        <li><span>Personengesellschaften,</span></li>
        <li><span>Neuerungen im Umsatzsteuer- und Bilanzsteuerrecht,</span></li>
        <li><span>Rechnungslegung und</span></li>
        <li><span>Berufsrecht.</span></li>
      </ul>
      <p>
        Ebenso wichtig ist es uns, dass wir uns persönlich weiterentwickeln: in
        Inhouse-Seminaren und Coachings. Wir erleben es immer wieder, wie
        wertvoll es ist, genau hinzuschauen. Wie erleichternd es sein kann, sich
        bewusst zu sensibilisieren und neu auszurichten, wenn Fragen kreisen wie
      </p>
      <ul>
        <li><span>Was stärkt uns? Was bremst?</span></li>
        <li>
          <span
            >Wie kommunizieren wir wertschätzend? Wie hören wir gut zu?</span
          >
        </li>
        <li>
          <span>Wie sorgen wir gut für uns, wenn es turbulent zugeht?</span>
        </li>
        <li>
          <span>
            Wie richten wir uns mental auf, wenn uns vieles gleichzeitig
            beschäftigt?
          </span>
        </li>
      </ul>
      <p>
        Aus Freude, Fortschritte zu gestalten und Neues zu entdecken:
        Weiterbildung bei KAMMERS & PARTNER.
      </p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery";
import Footer from "@/components/Footer";

export default {
  name: "Weiterbildung",
  metaInfo: {
    title: 'Weiterbildung',
    titleTemplate: '%s | Kammers & Partner'
  },
  components: { Footer, Gallery }
};
</script>

<style scoped></style>
