<template>
  <div>
    <div class="generic__site">
      <Gallery></Gallery>
      <h2>Immer mehr Unternehmer entscheiden sich für Stiftungen</h2>
      <p>
        Firmeninhaber errichten eine Stiftung als ein zukunftssicherndes
        Instrument der Unternehmensnachfolge. Oder Unternehmerinnen und
        Unternehmer sind entschlossen, ihr Vermögen langfristig zu sichern.
      </p>
      <p>
        Wer entschieden ist oder darüber nachdenkt, eine Stiftung zu errichten, ist
        bei uns in guten Händen. Wir engagieren uns dafür, dass Ihre Familien-,
        Unternehmens- oder gemeinnützige Stiftung auf einem sicheren Fundament
        gründet.
      </p>
      <p>
        Von Anfang an begleiten und vertreten wir Sie vor den
        Regierungsbehörden. Wir sind an Ihrer Seite, bis Ihre Stiftung anerkannt ist. Und natürlich darüber hinaus.
      </p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery";
import Footer from "@/components/Footer";

export default {
  name: "Stiftungen",
  metaInfo: {
    title: 'Stiftungen',
    titleTemplate: '%s | Kammers & Partner'
  },
  components: { Footer, Gallery }
};
</script>

<style scoped></style>
