<template>
  <div>
    <Navigation :key="$route.fullPath" id="Navigation"></Navigation>
    <NavigationBurger id="NavigationBurger"></NavigationBurger>
  </div>
</template>

<script>
import NavigationBurger from "@/components/NavigationBurger";
import Navigation from "@/components/Navigation";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Navigation, NavigationBurger }
};
</script>

<style lang="scss" scoped>
@import "../style/variables.scss";

@media screen and (max-width: 780px) {
  #Navigation {
    display: none;
  }
  #NavigationBurger {
    display: unset !important;
  }
}
#NavigationBurger {
  display: none;
}
</style>
