import { render, staticRenderFns } from "./NavigationBurger.vue?vue&type=template&id=447c4590&scoped=true&"
import script from "./NavigationBurger.vue?vue&type=script&lang=js&"
export * from "./NavigationBurger.vue?vue&type=script&lang=js&"
import style0 from "./NavigationBurger.vue?vue&type=style&index=0&id=447c4590&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "447c4590",
  null
  
)

export default component.exports