<template>
<Gesundheit></Gesundheit>
</template>

<script>
import Gesundheit from "../../components/Menschen/Gesundheit";

export default {
components: {Gesundheit}
}
</script>

<style scoped>

</style>