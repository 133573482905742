<template>
  <Kompetenz></Kompetenz>
</template>

<script>
import Kompetenz from "../../components/Kanzlei/Kompetenz";

export default {
  components: { Kompetenz }
};
</script>

<style scoped></style>
