<template>
  <Haltung></Haltung>
</template>

<script>
import Haltung from "../../components/Kanzlei/Haltung";

export default {
  components: { Haltung }
};
</script>

<style scoped></style>
