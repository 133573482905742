<template>
  <div>
    <div class="generic__site">
      <Gallery></Gallery>
      <h2>Unternehmen Sie etwas. Wir übernehmen den Rest.</h2>
      <p>
        Wir beraten Betriebe vom Ein-Personen-Unternehmen bis hin zum größeren
        Unternehmensverbund in allen steuerlichen und
        betriebswirtschaftlichen Fragen und Aufgaben. Wir stehen Ihnen zur Seite
        rund um
      </p>
      <ul>
        <li><span>Unternehmensgründungen,</span></li>
        <li><span>Unternehmensentwicklungen,</span></li>
        <li><span>Personalveränderungen,</span></li>
        <li><span>Unternehmensnachfolge,</span></li>
        <li><span>neue Gesellschafterstrukturen oder</span></li>
        <li><span>andere Veränderungsprozesse im Unternehmen.</span></li>
      </ul>
      <p class="highlight">Sie haben allen Grund aufzuatmen</p>
      <p>
        Gründer unterstützen wir dabei, die adäquate Rechtsform zu wählen. Wir
        bewerten und prognostizieren, ob oder wie das neue Vorhaben
        wirtschaftlich tragfähig zu gestalten ist.
      </p>
      <p>
        Sie sind nicht allein. Wir begleiten Sie und Ihre
        Unternehmensentwicklung professionell.
      </p>
      <p class="p__before__list">Wir</p>
      <ul class="list__after__p">
        <li><span>machen die Buchführung für Sie,</span></li>
        <li><span>rechnen Löhne und Gehälter ab,</span></li>
        <li><span>erstellen den Jahresabschluss,</span></li>
        <li>
          <span
            >kümmern uns um sämtliche steuerliche Erklärungspflichten und</span
          >
        </li>
        <li><span>wickeln Ihre To-dos fristgerecht und effizient ab.</span></li>
      </ul>
      <p class="highlight">Wenn sich Betriebe neu aufstellen, sind immer Menschen betroffen</p>
      <p>
        Mitarbeiter, Eigentümer, Investoren, Geschäftspartner oder
        Familienmitglieder nehmen Anteil am Geschehen. Ganz gleich, ob die
        Verantwortlichen damit konfrontiert sind, die Unternehmensnachfolge zu
        regeln, oder sich der Kreis der Gesellschafter oder Geschäftsführer neu
        zusammensetzt: Wendepunkte sind Chancen, vorausgesetzt die Akteure gehen
        sachkundig, verständnisvoll und einfühlsam miteinander um. Besonders in
        der Gestaltungsberatung gilt es, das Fachliche zu meistern und das
        Menschliche zu achten.
      </p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery";
import Footer from "@/components/Footer";

export default {
  name: "Unternehmen",
  metaInfo: {
    title: 'Unternehmen',
    titleTemplate: '%s | Kammers & Partner'
  },
  components: { Footer, Gallery }
};
</script>

<style scoped></style>
