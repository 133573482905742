<template>
<weiterbildung></weiterbildung>
</template>

<script>
import Weiterbildung from "../../components/Karriere/Weiterbildung";

export default {
components: {Weiterbildung}
}
</script>

<style scoped>

</style>