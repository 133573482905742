<template>
  <div>
    <div class="image__container">
      <div v-for="(item, index) in this.images" :key="index">
        <img
          v-bind:id="item.id"
          v-bind:src="item.thumbnail"
          @click="zoom(item, true)"
        />
      </div>
    </div>
    <transition name="fade">
      <div v-if="fullscreen">
        <transition name="fade" mode="in-out">
          <img
            v-if="this.selectedImage.format === 'normal'"
            @click="unZoom"
            class="hero-image-img  "
            :id="this.selectedImage.id"
            :src="this.selectedImage.image"
            :key="this.selectedImage.image"
          />
          <transition name="fade" mode="in-out">
            <div
              @click="unZoom"
              class="upright__container"
              v-if="this.selectedImage.format === 'upright'"
              :key="this.selectedImage.image"
            >
              <img
                @click="unZoom"
                class="upright__img"
                :id="this.selectedImage.id"
                :src="this.selectedImage.image"
              />
            </div>
          </transition>
        </transition>
      </div>
    </transition>

    <vuescroll
      style="
  white-space: nowrap;"
      id="slide__show"
      :ops="ops"
      ref="vs"
    >
      <div style="display: inline!important;">
        <div
          style="display: inline!important;"
          v-for="(item, index) in this.images"
          :key="index"
        >
          <img v-bind:id="item.id" v-bind:src="item.image" />
        </div>
      </div>
    </vuescroll>
    <div class="partner__resp"></div>
  </div>
</template>

<script>
import vuescroll from "vuescroll";

export default {
  name: "Gallery",
  components: { vuescroll },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {
          scrollingY: true
        },
        rail: {},
        bar: { keepShow: false, background: "grey", size: "2px" }
      },
      fullscreen: false,
      selectedImage: null,
      thumbnail: null,
      images: null
    };
  },
  mounted() {},
  methods: {
    /*    unsetThumbnails() {
      this.images.forEach(element => {
        document.getElementById(element.id).style.position = "unset";
        document.getElementById(element.id).style.zIndex = "0";
        document.getElementById(element.id).style.opacity = "1";
      });
    },
    createThumbnails(id) {
      console.log(id);
      // eslint-disable-next-line no-unused-vars
      let scrolled = window.scrollY;
      // eslint-disable-next-line no-unused-vars
      let top = document.getElementById(id).offsetTop;
      let sizes = [];
      this.images.forEach(element =>
        sizes.push(
          document
            .getElementById(element.id)
            .offsetLeft.toString()
            .concat("px")
        )
      );
      // eslint-disable-next-line no-unused-vars
      let left = document
        .getElementById(id)
        .offsetLeft.toString()
        .concat("px");
      let i;
      console.log(sizes);
      for (i = 0; i < sizes.length; i++) {
        console.log(sizes[i]);
        document.getElementById(i).style.left = sizes[i];
        document.getElementById(i).style.position = "fixed";
        document.getElementById(i).style.zIndex = "100";
        document.getElementById(i).style.top = (top - scrolled)
          .toString()
          .concat("px");
        document.getElementById(i).style.opacity = "0";
      }
      document.getElementById(id).style.opacity = "1";
    },*/
    // eslint-disable-next-line no-unused-vars
    zoom(image, click) {
      if (click) {
        this.$store.commit("setClickable", true);
      }
      if (this.$store.getters.clickable === true) {
        //this.createThumbnails(image.id);
        this.selectedImage = image;
        this.fullscreen = true;
        document.getElementsByTagName("BODY")[0].style.overflowY = "hidden";
      }
    },
    unZoom() {
      document.getElementsByTagName("BODY")[0].style.overflowY = "unset";
      //this.unsetThumbnails();
      this.fullscreen = false;
      this.thumbnail = null;
    },
    makeClickable() {
      this.$store.commit("setClickable", true);
    }
  },
  beforeMount() {
    if (this.$route.path === "/") {
      this.images = this.$store.getters.startseiteImages;
    }
    if (
      this.$route.path === "/portraet" ||
      this.$route.path === "/haltung" ||
      this.$route.path === "/kompetenz"
    ) {
      this.images = this.$store.getters.kanzleiImages;
    }
    if (this.$route.path === "/team") {
      this.images = this.$store.getters.teamImages;
    }
    if (this.$route.path === "/partner") {
      this.images = this.$store.getters.partnerImages;
    }
    if (
      this.$route.path === "/partnerschaft" ||
      this.$route.path === "/stellenangebote" ||
      this.$route.path === "/weiterbildung" ||
      this.$route.path === "/ihr-arbeitgeber"
    ) {
      this.images = this.$store.getters.karriereImages;
    }
    if (
      this.$route.path === "/privatpersonen" ||
      this.$route.path === "/start-ups" ||
      this.$route.path === "/stiftungen" ||
      this.$route.path === "/unternehmen"
    ) {
      this.images = this.$store.getters.leistungenImages;
    }
    if (this.$route.path === "/kontakt") {
      this.images = this.$store.getters.kontaktImages;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../style/variables.scss";
@media screen and (max-width: 780px) {
  #slide__show {
    display: block !important;
  }
  .image__container {
    display: none;
  }
  img {
    max-width: calc(100vw - 2 * 1rem);
    max-height: 40vh !important;
    min-height: 40vh !important;
    object-fit: cover;
  }
}
@media screen and (min-width: 780px) and (max-width: 820px) {
  .image__container img {
    max-height: 16.5vh !important;
  }
}
@media screen and (min-width: 821px) and (max-width: 1200px) {
  .image__container img {
    max-height: 18vh !important;
  }
}
@media screen and (min-width: 1201px) {
  .image__container img {
    max-height: 22vh !important;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0.1;
}
.image__container div {
  display: inline-block;
}

.image__container img {
  max-height: $maxThumbnailHeight;
  padding-right: $spaceBetweenElements;
  cursor: pointer;
}
.image__container div:last-child img {
  padding-right: 0;
}

.fullscreen__container {
  position: relative;
}

.hero-image {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
}
.hero-image-img {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.upright__container {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
}
.upright__img {
  max-width: 100%;
  max-height: 100%;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  -o-object-fit: contain;
  object-fit: contain;
  background: #ffffff;
}

#slide__show {
  display: none;
}
</style>
