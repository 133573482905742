<template>
<Datenschutz></Datenschutz>
</template>

<script>
import Datenschutz from "../../components/Startseite/Datenschutz";

export default {
components: {Datenschutz}
}
</script>

<style scoped>

</style>