<template>
  <div>
    <div class="generic__site">
      <h1>
        Digitaler Wandel gelingt gemeinsam, nicht einsam
      </h1>
      <p>
        Die Digitalisierung kann umständlich und aufreibend sein. Muss sie aber nicht, weiß Dr. Heinz Kammers. Seine
        Maxime: Automatisierung ist dann gut, wenn sie die Arbeit erleichtert und Freude bringt.
    Im IT-Special der DStR 20/ 2021 beschreibt er drei Erfolgsfaktoren für die digitale Transformation in Steuerberatungs­kanzleien. Best Practice einer digitalisierten Kanzlei.
      </p>
      <p>
        <a
            class="refs"
            style="font-style: italic"
            href="./DStR_20_2021_ASP_ IT-Special_Kammers.pdf"
            target="_blank"
        >Download</a
        >
      </p>
      <h2>Mut zum Gespräch in digitalen Zeiten</h2>
      <p>
        "In unserem Leben der potenzierten Geschwindigkeiten gehen uns
        Innehalten und Stillsein verloren. Die Kommunikation ist zu einem großen
        Teil zu einer emotionslosen Informiertheit verkümmert", sagt Dr. Heinz
        Kammers im Interview mit Susanne Kleiner für das IT Special in der DStR
        Deutsches Steuerrecht 41/ 2019.
      </p>
      <p>
        <a
            class="refs"
            style="font-style: italic"
            href="./1910-Mut-zum-Gespraech-Kleiner-Kammers.pdf"
            target="_blank"
        >Download</a
        >
      </p>
      <h2>Digitalisierung gestalten – Freiräume schaffen</h2>
      <p>
        Es gilt, die digitale Transformation aktiv zu gestalten und die
        Digitalisierung der Arbeitswelt als Chance zu begreifen: um Freiräume
        für ein gutes Miteinander von Mensch zu Mensch zu schaffen. Für ein
        zukunftsfähiges Wirtschaften. Dr. Heinz Kammers im Gespräch.
      </p>
      <p>Erschienen am 25. März 2019 im Kanzleiforum Beck-Shop online.</p>
      <p>
        <a
            style="font-style: italic"
            class="refs"
            href="http://kanzleiforum.beck-shop.de/2019/03/25/digitalisierung-gestalten-freiraeume-gewinnen/"
        >Download</a
        >
      </p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "Veroeffentlichungen",
  metaInfo: {
    title: "Veroeffentlichungen",
    titleTemplate: "%s | Kammers & Partner"
  },
  components: {Footer}
};
</script>

<style scoped></style>
