<template>
  <div>
    <div class="generic__site"></div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "Personelles",
  metaInfo: {
    title: 'Personelles',
    titleTemplate: '%s | Kammers & Partner'
  },
  components: {Footer}
};
</script>

<style scoped></style>
