<template>
  <footer>
    <div style="width: 100%; ">
      <router-link id="netzwerk" to="/netzwerk"
        >Netzwerk
      </router-link>
      <router-link id="kontakt" to="/kontakt"
        >Kontakt
      </router-link>
      <router-link id="datenschutz" to="/datenschutz"
        >Datenschutz
      </router-link>
      <router-link id="impressum" to="/impressum"
        >Impressum
      </router-link>
    </div>
  </footer>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import variables from "@/style/variables.scss";

export default {
  name: "Footer",
  mounted() {
    this.checkSelected();
  },
  methods: {
    getAllFooterRoutes() {
      return [
        document.getElementById("netzwerk"),
        document.getElementById("kontakt"),
        document.getElementById("datenschutz"),
        document.getElementById("impressum")
      ];
    },
    checkSelected() {
      if (
        this.$route.path !== "/netzwerk" &&
        this.$route.path !== "/kontakt" &&
        this.$route.path !== "/datenschutz" &&
        this.$route.path !== "/impressum"
      ) {
        this.getAllFooterRoutes().forEach(
          element => (element.style.opacity = "1")
        );
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../style/variables.scss";

@media screen and (max-width: 780px) {
  footer {
    display: none;
  }
}

a {
  padding-top: $spaceBetweenElements !important;
  padding-right: $spaceBetweenElements;
  opacity: 0.3;
  color: $headlineColor;
  text-decoration: none;
  font-size: $headlineFontSize;
}

a.router-link-active,
.active__router,
footer span {
  text-decoration: none;
  color: $headlineColor;
  opacity: 1;
  font-size: $headlineFontSize;
}

footer {
  margin-top: 2rem;
  padding-left: $paddingLeft;
  border-top: 1px solid $headlineColor !important;
  height: $navHeight;
}

footer div {
  padding-top: $navElementPaddingTop;
}
</style>
