<template>
<Home>
</Home>
</template>

<script>
import Home from "../../components/Startseite/Home";

export default {
components: {Home}
}
</script>

<style scoped>

</style>