<template>
  <div>
    <div class="generic__site">
      <Gallery id="gallery__large"></Gallery>
      <div id="gallery__resp">
        <div
            v-for="(item, index) in this.$store.getters.partnerImages"
            :key="index"
        >
          <img
              v-bind:id="item.id"
              v-bind:src="item.image"
              @click="zoom(item)"
          />
        </div>
      </div>
      <p>
        Die Partner unserer Kanzlei (v.l.n.r.): Dr. Heinz Kammers, Mathias Uhle und Armin Stöhr.<br/>
        Wir wachsen. Am liebsten gemeinsam mit Ihnen.
        <router-link to="/partnerschaft" class="refs"
        >Als Partnerin oder Partner unserer Kanzlei.
        </router-link
        >
        Interesse?
        <a class="refs" href="mailto:info@kammers-partner.de"
        >Schreiben Sie uns.</a
        >
      </p>
      <div class="wrap-collabsible">
        <hr/>

        <input
            id="kammers"
            class="toggle"
            type="checkbox"
            name="description"
            @click="check"
        />
        <label for="kammers" class="lbl-toggle">Dr. Heinz Kammers</label>
        <div class="collapsible-content">
          <div class="content-inner">
            <p>
              Diplom-Kaufmann <br/>
              Steuerberater <br/>
              Wirtschaftsprüfer
            </p>
            <p>
              Zertifizierter Testamentsvollstrecker (Institut für Erbrecht e.V.) <br/>
              Mediator <br/>
              Coach / Berater (AfW)
            </p>
            <p>
              „Vertrauen. Ich kenne kein schöneres und treffenderes Wort als Garant für eine zutiefst menschliche
              Verbundenheit und zugleich erfolgreiche Zusammenarbeit.“
            </p>
          </div>
        </div>
        <hr/>
        <input
            id="uhle_math"
            class="toggle"
            type="checkbox"
            name="description"
            @click="check"
        />
        <label for="uhle_math" class="lbl-toggle">Mathias Uhle</label>
        <div class="collapsible-content">
          <div class="content-inner">
            <p>
              Diplom-Kaufmann <br/>
              Diplom-Ingenieur (BA) <br/>
              Steuerberater <br/>
              Wirtschaftsprüfer <br/>
            </p>
            <p>
              „Wir wollen ein attraktives Beratungsunternehmen sein. Für Mandanten, Mitarbeiter und Partner.“
            </p>
          </div>
        </div>
        <hr/>

        <input
            id="stoehr"
            class="toggle"
            type="checkbox"
            name="stoehr"
            @click="check"
        />
        <label for="stoehr" class="lbl-toggle">Armin Stöhr</label>
        <div class="collapsible-content">
          <div class="content-inner">
            <p>
              Diplom-Ökonom<br/>
              Steuerberater
            </p>
            <p>
              „Gerne erinnere ich mich an die Worte eines langjährigen Mandanten und Freundes: ‚Fachlich und menschlich stimmt es einfach.‘“</p>
          </div>
        </div>
        <hr/>

      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery";
import TransitionExpand from "@/components/TransitionExpand";
import Footer from "@/components/Footer";

export default {
  name: "Partner",
  metaInfo: {
    title: 'Partner',
    titleTemplate: '%s | Kammers & Partner'
  },
  // eslint-disable-next-line vue/no-unused-components
  components: {Footer, Gallery, TransitionExpand},
  methods: {
    check() {
      let unMarkedCheckbox = document.querySelectorAll(
          'input[type="checkbox"]'
      );
      let markedCheckbox = document.querySelectorAll(
          'input[type="checkbox"]:checked'
      );
      unMarkedCheckbox.forEach(element => {
        element.labels[0].style.opacity = "0.3";
      });
      markedCheckbox.forEach(element => {
        element.labels[0].style.opacity = "1";
      });
      if (markedCheckbox.length === 0) {
        unMarkedCheckbox.forEach(element => {
          element.labels[0].style.opacity = "1";
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../style/variables.scss";

@media screen and (max-width: 780px) {
  hr {
    width: calc(100vw + 1em) !important;
    margin-left: -2rem;
  }
  #gallery__large {
    display: none;
  }
  #gallery__resp div {
    float: left;
    padding-bottom: 0.5rem;
  }
  #gallery__resp div:nth-child(1),
  div:nth-child(3) {
    padding-right: 0.5rem;
  }
  img {
    display: block;
    max-width: calc(50vw - 1.25rem);
  }
  .toggle:checked + .lbl-toggle + .collapsible-content {
    min-height: unset!important;
    max-height: 60vh!important;
}
}

@media screen and (min-width: 781px) {
  #gallery__resp {
    display: none;
  }
}

.generic__site {
  overflow-x: hidden;
}

wrap-collabsible {
  margin-bottom: 1.2rem;
}

input[type="checkbox"] {
  display: none;
}

hr {
  width: calc(100vw - 0rem);
  margin-left: -2rem;
  background: $headlineColor !important;
}

.lbl-toggle {
  color: $headlineColor;
  font-size: $contentFontSize;
  font-weight: normal;
  font-family: ArnhemFine-Normal;
  padding-bottom: 0.1rem;
  padding-top: 0.1rem;
  margin-left: -2rem;
  padding-left: 2rem;
  display: block;
  text-align: left;
  cursor: pointer;
  opacity: 1;
  transition: 0.5s opacity linear !important;
}

.collapsible-content {
  width: calc(100vw - 4rem) !important;

  color: $headlineColor;
  min-height: 0;
  max-height: 0;
  overflow: hidden;
  transition: 0.5s linear !important;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
  min-height: 10vh;
  max-height: 50vh;
  transition: 0.5s linear !important;
}

.collapsible-content .content-inner {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  //transition: max-height 0.5s linear !important;
}
</style>
