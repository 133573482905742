<template>
  <div>
    <div class="generic__site">
      <a class="pdf" href="./Datenschutzerklaerung.pdf" target="_blank"
        >Datenschutzerklärung</a
      >
      <br />
      <a class="pdf" href="./Pflichtinformationen.pdf" target="_blank"
        >Pflichtinformationen gem. Art. 12ff. DS-GVO</a
      >
      <br />
      <a class="pdf" href="./Disclaimer.pdf" target="_blank">Disclaimer</a>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "Datenschutz",
  metaInfo: {
    title: "Datenschutz",
    titleTemplate: "%s | Kammers & Partner"
  },
  components: { Footer },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};
</script>

<style lang="scss" scoped>
@import "../../style/variables.scss";
</style>
