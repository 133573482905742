<template>
  <div>
    <div class="generic__site">
      <h1>
        Gesundheit
      </h1>
      <p>
        Bettina Marx,
        Gesundheitscoach, unterstützt uns regelmäßig dabei, Gesundheit am
        Arbeitsplatz zu fördern. Sie analysiert individuell die Arbeitsplätze
        und berät uns nach ergonomischen Kriterien. Sie bringt uns
        Bewegungsprogramme am Arbeitsplatz näher, stärkt uns mit ihrer
        Rückenschule und zeigt uns Entspannungsübungen.
      </p>
      <p>
        Mit dem Kur- und Gesundheitszentrum Friedborn
        verbindet uns eine langjährige Freundschaft. Wir unterstützen das
        Zentrum und fördern Aufenthalte unserer Mitarbeiter und Mitarbeiterinnen
        im Hotzenwald. Das ganzheitliche Konzept überzeugt. Im Zentrum steht
        eine selbstverantwortete Gesundheit nach wissenschaftlichen und
        naturheilkundlichen Erfahrungen. Die Gäste wählen zwischen Heilfasten,
        einer Basendiät oder Vollwerternährung.
      </p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
export default {
  name: "Gesundheit",
  metaInfo: {
    title: 'Gesundheit',
    titleTemplate: '%s | Kammers & Partner'
  },
  components: { Footer }
};
</script>

<style scoped></style>
